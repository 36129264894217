import defaut_dog from "../images/default-dog.jpg"
import defaut_dog2 from "../images/dfwlab_photo_coming_soon_2022.jpg"

export const defaultDog = defaut_dog;
export const defaultDog2 = defaut_dog2;
export const defaultMonths = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
]
export const phoneTypes = [
    { name: "Mobile", key: "Mobile" },
    { name: "Home", key: "Home" },
    { name: "Work", key: "Work" }
]
export const emailFormat =  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
export const phoneFormat = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
export const zipFormat = /\d{5}/
export const phoneFormatField = "(999) 999-9999";
export const zipFormatField = "99999";
export const dwellTypes = [
    "House",
    "Townhouse",
    "Condo",
    "Apartment",
    "Other",
]
export const ownRent = [
    "Own",
    "Rent"
]
export const yesNo = [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
]
export const genders = [
    "Male",
    "Female",
    "Either"
]
export const colorPreferences = [
    "Black",
    "Yellow",
    "Chocolate"
]
export const ageRangePreferences = [
    "< 6 months",
    "6 months - 1 year",
    "1 - 3 years",
    "4 - 6 years",
    "6+ years",
]
export const unwillingBehaviors = [
    "Cat aggressive",
    "Destructive chewing",
    "Digging",
    "Dog aggressive",
    "Eliminating in the house",
    "Escaping",
    "Excessive barking",
    "Jumping up",
    "Mouthiness",
    "Rowdy behavior",
    "Scratching at doors",
    "Separation anxiety",
    "Thunderstorm anxiety",
    "Other",

]

export const indoorOutdoor = [
    "Indoor",
    "Outdoor"
]
export const insideOutside5050 = [
    { label: "Inside", value: "Inside" },
    { label: "Outside", value: "Outside" },
    { label: "50/50", value: "50/50" },
]
export const defaultPets = {
    pet_name: "",
    pet_breed: "",
    pet_age: "",
    pet_gender: "",
    pet_spayed_neutered: "",
    pet_current_vaccinations: "",
    pet_current_heartworm: "",
    pet_current_inside_outside:""
}

export const defaultCurrentDogs = {
    curr_dog_name: "",
    curr_dog_breed: "",
    curr_dog_age: "",
    curr_dog_gender: "",
    curr_dog_spayed_neutered: "",
    curr_dog_inside_outside: "",
    curr_dog_heartworm:"",
    curr_dog_vaccinations:""
}

export const defaultPastDogs = {
    past_dog_name: "",
    past_dog_breed: "",
    past_dog_gender: "",
    past_dog_age_reached: "",
    past_dog_what_happen: "",
}

export const defaultReference = {
    ref_name: "",
    ref_years_known: "",
    ref_primary_phone_type: "Mobile",
    ref_primary_phone: "",
    ref_alternative_phone_type: "Mobile",
    ref_alternative_phone: "",
}
export const defaultCoApplicants = {
    co_app_first_name: "",
    co_app_last_name: "",
    co_app_primary_phone_type: "Mobile",
    co_app_primary_phone: "",
    co_app_alternative_phone_type: "Mobile",
    co_app_alternative_phone: "",
}

export const dogStatus = [
    'Available',
    'Adopted',
    'Coming Soon',
    'Adoption Pending',
    'Deceased',
    'PTS',
    'Lost',
    'Intake',
    'Welcome',
    'Transferred Out',
    'Hospice',
    'Died in Rescue',
]