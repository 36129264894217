import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import InnerBanner from "../components/shared/inner-banner"
import Sections from "../components/special-dogs/sections"
import banner from "../images/special-dogs-banner-bg.jpg"
const SpecialNeedsDogsPage = () => {

  return <Layout colored_header={true}>
    <Seo title="Special Needs Dogs" />
    <InnerBanner theme="white" image={banner} title="Special Needs Dogs" subtitle="Dogs" />
    <Sections.Section1/>
    <Sections.DonateButtons/>
    <Sections.Section2/>
    <Sections.DonateButtons/>
   
  </Layout>
}

export default SpecialNeedsDogsPage