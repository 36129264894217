import "./sections.css"

import React from "react"

import axios from "axios"
import { Link } from "gatsby"

import { API_BASE_URL } from "../../constants/config"
import { defaultDog } from "../../constants/defaults"

const Section1 = () => {
    return <section className="special-sec1">
        <div className="content">
            <p>Special Needs Labs are those Labs that are brought into our program knowing they may have physical or behavioral issues that are greater than our typical vetting and fostering programs can overcome.  These Labs may have a serious illness that will require hospitalization, may need surgery to repair an injury or genetic defect, may need specialized testing and treatment, or may need to spend time with a trainer to overcome behavioral challenges.</p>
            <p>It is through donations to our Special Needs Fund that we are able to offer help to a Lab in Need.  We feel by helping these special Labs we are taking rescue to the next level. </p>

        </div>
    </section>
}
const Section2 = () => {

    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        var m = true;
        if (m) {
            axios.get(API_BASE_URL + "/website/dogs/special-dogs").then(({ data }) => {
                setData(data.data.data);
            })
        }
        return () => {
            m = false;
        }
    }, [])

    return <section className="special-sec2">
        <div className="content">

            {data && data.map((item, key) => {
                return <div key={key} className="item">
                    <div className="thumb">

                        <img src={item.featured_image ? API_BASE_URL.replace("/v1", "/") + item.featured_image.path : defaultDog} alt={item.title} />
                    </div>
                    <div className="text">
                        <h3>{item.title}</h3>
                        <div className="text-content site-content" dangerouslySetInnerHTML={{ __html: item.content }} />
                    </div>
                </div>
            })}
        </div>
    </section>
}
const DonateButtons = () => {

    return <section className="special-donate-buttons">
        <div className="content">
            <span>Consider making a donation to our Special Needs Fund by clicking this button.</span>
            <Link className="btn-primary" to="/donate/">Donate</Link>
        </div>
    </section>
}
const Sections = {
    Section1,
    Section2,
    DonateButtons
}

export default Sections